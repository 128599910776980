/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { login } from '../actions/users';

const Auth = () => {
  const dispatch = useDispatch();
  // see if a redirect URL was passed in the query params
  const urlParams = new URLSearchParams(window.location.search);
  const redirectUrl = urlParams?.get('redirectUrl')?.toString();
  useEffect(() => {
    dispatch(login(redirectUrl));
    dispatch(replace('/'));
  }, []);
  return <></>;
};

export default Auth;
