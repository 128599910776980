import { push } from 'connected-react-router';
import jwt from 'jsonwebtoken';
import { getToken } from '../helpers/session';
import logError from '../helpers/logger';
import {
  USER_LOGIN,
  USER_LOGIN_SUCCESSFUL,
  USER_LOGIN_FAILURE,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_SUCCESSFUL,
  USER_UPDATE_PROFILE_FAILURE,
  USER_RESET_FORM_STATE,
} from './users.actions';
import { Action } from '../typings/types';
import notify from '../helpers/notification';

const notifyUpdatedProfile = () => notify('saved', 'updated_profile');

export const userLogin = (): Action => ({
  type: USER_LOGIN,
});

export const userLoginSuccessful = (token: any, data: any) => ({
  type: USER_LOGIN_SUCCESSFUL,
  token,
  data,
});

export const userLoginFailure = (code: number, message: string | null) => ({
  type: USER_LOGIN_FAILURE,
  code,
  message,
});

export const processSuccessfulLogin = (
  token: any,
  data: any,
  redirectUrl?: string
) => {
  return async (dispatch: any): Promise<void> => {
    dispatch(userLoginSuccessful(token, data));
    dispatch(push(redirectUrl || '/'));
  };
};

export const login = (redirectUrl?: string) => {
  return async (dispatch): Promise<void> => {
    dispatch(userLogin());
    const token = getToken();
    if (token) {
      let tokenData: string | { [key: string]: any } | null | any = null;
      try {
        tokenData = jwt.decode(token);
        if (tokenData.uuid) {
          dispatch(processSuccessfulLogin(token, tokenData, redirectUrl));
        } else {
          dispatch(userLoginFailure(403, null));
          throw new Error('Invalid access token');
        }
      } catch (e) {
        logError(e);
        dispatch(userLoginFailure(403, null));
      }
    } else {
      dispatch(userLoginFailure(403, null));
    }
  };
};

export const resetFormState = () => ({
  type: USER_RESET_FORM_STATE,
});

export const userUpdateProfile = () => ({
  type: USER_UPDATE_PROFILE,
});

export const userUpdateProfileSuccessful = (data) => ({
  type: USER_UPDATE_PROFILE_SUCCESSFUL,
  data,
});

export const userUpdateProfileFailure = (code, message) => ({
  type: USER_UPDATE_PROFILE_FAILURE,
  code,
  message,
});

export const updateUserProfile = (consented: boolean) => {
  return (dispatch): void => {
    dispatch(userUpdateProfile());
    dispatch(userUpdateProfileSuccessful(consented));
    setTimeout(() => {
      notifyUpdatedProfile();
      dispatch(resetFormState());
      dispatch(push('/studies'));
    }, 1000);
  };
};
