import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainContainer from '../../components/MainContainer';
import Title from '../../components/Title/Title';

const UnauthorizedUser: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <MainContainer>
      <Title>{t('unauthorized_user_title')}</Title>
      <div className="finbb-container">
        <div className="narrow-container">
          <p>{t('unauthorized_user_text')}</p>
          <button
            type="button"
            className="button button-wide button-link double-vertical-margin"
            onClick={() => history.push('/logout')}
          >
            {t('logout_button')}
          </button>
        </div>
      </div>
    </MainContainer>
  );
};

export default UnauthorizedUser;
